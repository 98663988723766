export const getWebServiceUrl = () => {
  if (process.env.REACT_APP_MODE === 'dev') {
    //return "http://localhost:8082/api";
    return "http://www.zero2me.com/api";
  } else {
    return "http://www.zero2me.com/api";
  }
};

export const getWebUrl = () => {
  if (process.env.REACT_APP_MODE === 'dev') {
    return "http://localhost:9999/";
    //return "http://www.zero2me.com/api";
  } else {
    return "http://www.zero2me.com/";
  }
};